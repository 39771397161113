import React, { useEffect, useState } from "react";
import {
	View,
	Text,
	StyleSheet,
	Image,
	ScrollView,
	TouchableOpacity,
} from "react-native";
import { useParams } from "react-router-dom";
import Font from "react-font";
import Lottie from "react-lottie";
import traq_logo from "./logo_admin.png";
import moment from "moment/moment";
import CsvExportButton from "./ExportToCsv";
import { ActivityIndicator } from "react-native-web";

const fetchData = async (eventId, urlToken, setData, setError) => {
	try {
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		const raw = JSON.stringify({
			event: eventId,
			urlToken: urlToken,
		});

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		const response = await fetch(
			"https://us-central1-traq-e3acf.cloudfunctions.net/app/rsvp/readall",
			requestOptions,
		);
		const result = await response.json();
		console.log(result);
		setData(result); // Set fetched data to state
	} catch (error) {
		console.error("Error fetching data: ", error);
		setError(error); // Handle error accordingly
	}
};

const RsvpExport = () => {
	const { eventId, urlToken } = useParams();
	const [data, setData] = useState([]);
	const [error, setError] = useState(null);

	useEffect(() => {
		fetchData(eventId, urlToken, setData, setError);
	}, [eventId]); // Re-run effect when eventId changes

	document.title = `RSVP Data - ${eventId}`;
	return (
		<View
			style={{
				backgroundColor: "#1F2229",
				width: window.innerWidth,
				height: window.innerHeight,
				overflow: "hidden",
			}}>
			<View
				style={{
					flexDirection: "row",
					alignItems: "center",
				}}>
				<Image
					source={traq_logo}
					style={{
						width: 80,
						height: 30,
						resizeMode: "contain",
						marginTop: 5,
						marginRight: 10,
					}}
				/>
				<Text
					style={{
						fontSize: 19,
						color: "#FFF",
					}}>
					<Font family='Poppins' weight={700}>
						RSVP management
					</Font>
				</Text>
			</View>

			<View style={{ padding: 10, opacity: 0.9 }}>
				{/* <Text
					style={{
						fontSize: 19,
						color: "#FFF",
					}}>
					<Font family='Poppins' weight={700}>
						RSVP management
					</Font>
				</Text> */}
				<View
					style={{
						flexDirection: "row",
					}}>
					<View
						style={{
							backgroundColor: "#FFF",
							padding: 10,
							borderRadius: 10,
							marginRight: 10,
							minWidth: 65,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<Text
							style={{
								fontSize: 25,
								color: "#1F2229",
							}}>
							<Font family='Poppins' weight={400}>
								{data.length}
							</Font>
						</Text>
					</View>
					<Text
						style={{
							fontSize: 19,
							fontWeight: "400",
							color: "#FFF",
						}}>
						<Text
							style={{
								fontSize: 10,
								fontWeight: "400",
								color: "#FFF",
							}}>
							<Font family='Poppins' weight={400}>
								event:
							</Font>
						</Text>
						<Font family='Poppins' weight={400}>
							{eventId}
						</Font>
					</Text>
				</View>
			</View>

			<div style={styles.row}>{/* Your Header Row Content */}</div>

			<View
				style={{
					flex: 1,
				}}>
				<ScrollView>
					<div style={styles.container}>
						{data.length > 0 ? (
							data.map((item, index) => (
								<View
									key={index}
									style={{
										padding: 10,
										// borderBottom: "1px solid #ccc",
										backgroundColor:
											"rgba(255, 255, 255, 0.05)",
										borderRadius: 10,
										marginBottom: 5,
									}}>
									<Text
										style={{
											fontSize: 14,
											width: 150,
											display: "none",
										}}>
										<Text
											style={{
												marginRight: 10,
												fontWeight: "500",
												color: "#FFF",
											}}>
											{item.rsvp_event
												.toString()
												.toUpperCase()}
										</Text>
									</Text>
									<View
										style={{
											flexDirection: "row",
											marginRight: 10,
											justifyContent: "space-between",
										}}>
										<Text
											style={{
												fontSize: 16,
												width: 200,
												color: "#FFF",
												fontWeight: "500",
											}}>
											{item.first_name} {item.last_name}
										</Text>
									</View>

									<View style={{ marginVertical: 5 }}>
										<Text
											style={{
												fontSize: 10,
												width: 200,
												color: "#FFF",
												opacity: 0.7,
											}}>
											Accepted:
										</Text>
										<Text
											style={{
												fontSize: 14,
												width: 200,
												color: "#FFF",
											}}>
											{" "}
											{moment(item.dateCreated).format(
												"DD/MM/YYYY HH:mm",
											)}
										</Text>
									</View>

									<View
										style={
											{
												// flexDirection: "row",
											}
										}>
										<View style={{ marginVertical: 5 }}>
											<Text
												style={{
													fontSize: 10,
													width: 200,
													color: "#FFF",
													opacity: 0.7,
												}}>
												Contact: {item.preferredMethod}
											</Text>
											<Text
												style={{
													fontSize: 14,
													width: 200,
													color: "#FFF",
												}}>
												{item.email !== ""
													? item.email
													: item.cell}
											</Text>
										</View>

										<View style={{ marginVertical: 5 }}>
											<Text
												style={{
													fontSize: 10,
													width: 200,
													color: "#FFF",
													opacity: 0.7,
												}}>
												Dietary
											</Text>
											<Text
												style={{
													fontSize: 14,
													width: 200,
													color: "#FFF",
												}}>
												{item.dietary}
											</Text>
										</View>

										<View
											style={{
												marginVertical: 5,
												width: "100%",
											}}>
											<Text
												style={{
													fontSize: 10,
													width: 200,
													color: "#FFF",
													opacity: 0.7,
												}}>
												Question:
											</Text>
											<Text
												style={{
													fontSize: 14,
													width: "100%",
													color: "#FFF",
												}}>
												{item.survey_q1}
											</Text>
										</View>
									</View>
								</View>
							))
						) : (
							<View
								style={{
									width: "100%",
									justifyContent: "center",
									alignItems: "center",
								}}>
								{error ? (
									<Text
										style={{
											marginRight: 10,
											fontWeight: "500",
											color: "#FFF",
										}}>
										Error loading data
									</Text>
								) : (
									<View
										style={{
											width: "100%",
											justifyContent: "center",
											alignItems: "center",
										}}>
										{error && (
											<Text
												style={{
													fontWeight: "500",
													color: "#FFF",
												}}>
												Error loading data
											</Text>
										)}
										<Text
											style={{
												marginRight: 10,
												fontWeight: "500",
												color: "#FFF",
											}}>
											Loading RSVPs
										</Text>
										<ActivityIndicator color={"#FFF"} />
									</View>
								)}
							</View>
						)}
					</div>
				</ScrollView>
			</View>

			<CsvExportButton dataParent={data} fileName={eventId} />

			<View
				style={{
					width: window.innerWidth,
					// position: "absolute",
					// bottom: 30,
					// left: 0,
					// padding: "10%",
					flexDirection: "row",
					justifyContent: "space-between",
					paddingRight: 5,
					paddingLeft: 10,
				}}>
				<View>
					<Text
						style={{
							// width: 70,
							fontSize: 14,
							// color: "#121419",
							color: "#FFF",
						}}>
						<Font family='Poppins' weight={800}>
							tetrice
						</Font>
					</Text>
					<Text
						style={{
							width: 110,
							fontSize: 14,
							color: "#FFF",
						}}>
						<Font family='Poppins' weight={300}>
							info@traq.app
						</Font>
					</Text>
				</View>
				<View
					style={{
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
					}}>
					<Image
						source={require("./google_play.png")}
						style={{
							width: 110,
							height: 32,
							resizeMode: "contain",
							marginRight: 10,
						}}
					/>

					<Image
						source={require("./app_store.png")}
						style={{
							width: 110,
							height: 32,
							resizeMode: "contain",
						}}
					/>
				</View>
			</View>
		</View>
	);
};

const styles = {
	container: {
		padding: "16px",
	},
	title: {
		fontSize: "24px",
		marginBottom: "16px",
		color: "#000",
	},
	row: {
		padding: "5px",
		borderBottom: "1px solid #ccc",
		display: "flex",
	},
	// ... More styles as needed
};

export default RsvpExport;
