import React, { Component } from "react";

import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import ElanRetinolPage from "./ElanRetinolPage";
import Page404 from "./404Page";
import Login from "./Unauthed/Login";
import ColorStayEvent from "./ColorStayEvent";
import RsvpExport from "./Dashboard";

import DashboardLogin from "./Dashboard/Unauthed/Login";
import ColorStayReminder from "./ReminderPage";
import JarvisChat from "./BeautyBot/JarvisChat";
import RsvpTemplate from "./RsvpTemplate";
import RsvpUrl from "./RsvpUrl";

class ExampleComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			urls: [
				{
					link: "comicconjar",
					title: "Comic con 8 Hour sample QR ",
				},
			],
		};
	}
	// comicconpinth
	render() {
		return (
			<Router>
				<Routes>
					<Route path={`/`} element={<Login />} />
					<Route
						path={`/elanretinol`}
						element={<ElanRetinolPage />}
					/>

					<Route
						path={`/colorstayatrockets`}
						element={<ColorStayEvent />}
					/>

					<Route
						path={`/rsvp/:eventId/:urlToken`}
						element={<RsvpUrl />}
					/>

					{/* Dashboard for exporting of RSVPS's */}
					<Route
						path={`/rsvpdata/:eventId/:urlToken`}
						element={<RsvpExport />}
					/>
					{/* Dashboard for exporting of RSVPS's */}

					<Route path={`/dashboard`} element={<DashboardLogin />} />

					<Route
						path={`/colorstayatrockets/countdown`}
						element={<ColorStayReminder />}
					/>

					<Route path={`/julia/bot`} element={<JarvisChat />} />

					{/* {this.state.urls.map((d, i) => (
						<Route
							path={`/${d.link}`}
							element={
								<EightHourComponent
									urlPath={"8 Hour comic con sample QR"}
								/>
							}
						/>
					))} */}

					{/* <Route
						path='/signupform'
						exact
						element={<SignupTracker />}
					/>

					<Route path='/thankyou' exact element={<ThankYou />} />

					<Route path='/entercomp' element={<Comp />} /> */}

					{/* <Route
						path={`/instagram`}
						element={<EightHourComponent urlPath={"instagram"} />}
					/>

					<Route
						path={`/instabuynow`}
						element={
							<EightHourComponent
								urlPath={"comic con instagram"}
							/>
						}
					/>

					<Route
						path={`/presskit`}
						element={
							<EightHourComponent
								urlPath={"8 Hour presskit QR"}
							/>
						}
					/> */}
					<Route path='*' element={<Navigate to='/' />} />
				</Routes>
			</Router>
		);
	}
}

export default ExampleComponent;
