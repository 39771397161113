import { Dimensions, LayoutAnimation, UIManager, Platform } from "react-native";

if (Platform.OS === "android") {
	if (UIManager.setLayoutAnimationEnabledExperimental) {
		UIManager.setLayoutAnimationEnabledExperimental(true);
	}
}

export const APP_COLOURS = {
	PRIMARY: "#0068FF",
	PRIMARY2: "#FF5F5F",
	PRIMARY3: "#FFD76E",
	SECONDARY: "#C2EBD5",
	BACKGROUND: "#191D24",
	BACKGROUND_FADE: "#F0F3F6",
	// BACKGROUND_FADE: '#191D24',
	TEXTCOLOR: "#181A21",
	TEXTCOLOR2: "#F77062",
	TEXTCOLOR3: "#FC5C84",
	TEXTCOLOR4: "#878787",
	TEXTWHITE: "#E8E8E8",
	BLUE: "#518AFF",
	BLUE2: "#12326B",
	GREEN: "#59D654",
	PURPLE: "#996AFF",
};

export var CustomLayoutSpring = {
	duration: 600,
	create: {
		type: LayoutAnimation.Types.spring,
		property: LayoutAnimation.Properties.opacity,
		springDamping: 0.9,
	},
	update: {
		type: LayoutAnimation.Types.spring,
		property: LayoutAnimation.Properties.opacity,
		springDamping: 0.9,
	},
	delete: {
		type: LayoutAnimation.Types.spring,
		property: LayoutAnimation.Properties.opacity,
		springDamping: 0.9,
	},
};

export const screenWidth = Math.round(Dimensions.get("window").width);
export const screenHeight = Math.round(Dimensions.get("window").height);

// export const Avatars = [
//   {
//     pp: require('./assets/images/profile.png'),
//     avatar: 0,
//   },
// ];
