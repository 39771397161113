import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import RsvpTemplate from "./RsvpTemplate";

const fetchData = async (eventId, urlToken, setData, setError) => {
	try {
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		const raw = JSON.stringify({
			event: eventId,
			urlToken: urlToken,
		});

		const requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		const response = await fetch(
			"https://us-central1-traq-e3acf.cloudfunctions.net/app/rsvp/readall",
			requestOptions,
		);
		const result = await response.json();
		console.log(result);
		setData(result); // Set fetched data to state
	} catch (error) {
		console.error("Error fetching data: ", error);
		setError(error); // Handle error accordingly
	}
};

const RsvpUrl = () => {
	const { eventId, urlToken } = useParams();
	const [data, setData] = useState([]);
	const [error, setError] = useState(null);

	useEffect(() => {
		fetchData(eventId, urlToken, setData, setError);
	}, [eventId]); // Re-run effect when eventId changes

	document.title = `RSVP - ${eventId}`;
	return <RsvpTemplate eventId={eventId} urlToken={urlToken} data={data} />;
};

export default RsvpUrl;
