import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	ScrollView,
	Image as AutoHeightImage,
	Keyboard,
	Animated,
	TextInput,
	KeyboardAvoidingView,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import Lottie from "react-lottie";
import FullName from "./FullName";
import { screenHeight, screenWidth } from "./APP_VARIABLES";
// import AsyncStorage from "@react-native-async-storage/async-storage";
import tick from "./assets/images/tick.png";
// import {APP_COLOURS} from '../APP_VARS';
import CellNumber from "./CellNumber";
import EmailInput from "./EmailInput";

// import DateOfBirth from './DateOfBirth';
import Policy from "./Policy";
import OptIn from "./OptIn";
import MultiSelect from "./MultiSelect";
import * as animationData from "../animation_ln08boza.json";
import { api_formsubmit } from "../Api";
// import OptIn from './OptIn';

export default class RsvpForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rsvp_event: "elanretinol",
			first_name: "",
			last_name: "",
			title_name: "",
			cell: "",
			email: "",
			preferredMethod: "",
			policy: false,
			optin: false,
			keyboardActive: false,
			keyboardOffset: 0,
			step: "1",
			submitSuccess: false,
		};

		this.keyboardOffset = new Animated.Value(0);
	}

	componentDidMount() {
		this.setKeyboardListener();
	}

	async componentWillUnmount() {
		await console.log("unmounted");

		this.setState = (state, callback) => {
			return;
		};
		this.keyboardDidShowListener.remove();
		this.keyboardDidHideListener.remove();
	}

	submitForm = async () => {
		await Keyboard.dismiss();
		this.setState({
			loading: true,
		});

		let obj = {
			// name: `${this.state.first_name},${this.state.last_name}`,
			// email: this.state.email,
			// img: this.state.img,
			// title: this.state.title_name,
			// expectations_of_event: `${this.state.expectations_of_event},${this.state.answer_other}`,
			// opt_in: this.state.optin,

			...this.state,
		};

		console.log(obj);
		// return;
		// alert("No form submission at this time");
		// return;

		await fetch(api_formsubmit, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(obj),
		})
			.then((response) => {
				console.log(response);
				if (response.status === 200) {
					this.setState({
						loading: false,
						submitSuccess: true,
					});

					const setData = localStorage.setItem(
						"userData",
						JSON.stringify({
							...obj,
						}),
					);

					setTimeout(() => {
						this.props.close();
					}, 5000);
				} else {
					console.log(
						"loginFirebase Login Failed: ",
						response.status,
					);
					this.setState({
						loading: false,
					});
					setTimeout(() => {
						this.setState({
							loading: false,
						});
					}, 5000);
				}
			})
			.catch((error) => {
				console.log(error);
				alert("Network error!");
				this.setState({
					loading: false,
				});
			});
	};

	handleScrollToTop = () => {};

	render() {
		let sh = this.props.screenHeight;
		let sw = this.props.screenWidth;

		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};
		return (
			<View
				style={{
					flex: 1,
					width: "100%",
				}}>
				<KeyboardAvoidingView
					behavior='padding'
					style={{
						flex: 1,
						// justifyContent: 'center',
						// alignItems: 'center',
					}}>
					{/* <AutoHeightImage
						style={{
							width: "100%",
							// height: screenHeight - this.state.keyboardOffset,
							// resizeMode: 'contain',
							zIndex: 80,
							position: "absolute",
							top: 0,
						}}
						source={require("./assets/images/bgImg.png")}
					/> */}

					<View
						style={{
							flex: 1,
							zIndex: 99,
							height: screenHeight - this.state.keyboardOffset,
							alignItems: "center",
							width: "100%",
						}}>
						{/* <View
							style={{
								width: 180,
								paddingTop: 30,
								position: "absolute",
								left: 10,
								zIndex: 999,
							}}>
							<TouchableOpacity
								onPress={() => {
									this.props.close();
								}}>
								<View
									style={{
										justifyContent: "center",
										alignItems: "center",
										borderRadius: 15,
										padding: 10,
										backgroundColor: "#0A1526",
									}}>
									<Text
										style={{
											color: "#FFF",
											fontSize: 17,
											fontFamily: "Avenir",
										}}>
										Back
									</Text>
								</View>
							</TouchableOpacity>
						</View> */}
						<ScrollView
							ref={(ref) => {
								this.scrollViewRef = ref;
							}}
							showsVerticalScrollIndicator={false}
							contentContainerStyle={{
								width: "100%",
							}}>
							{this.state.loading ? (
								<View
									style={{
										padding: 100,
										justifyContent: "center",
										alignItems: "center",
										// backgroundColor: "red",
									}}>
									<View style={{}}>
										<Lottie
											options={defaultOptions}
											height={window.innerWidth * 0.8}
											width={window.innerWidth}
										/>
									</View>
									<ActivityIndicator
										color='#000'
										size={"small"}
									/>
									<Text
										style={{
											fontSize: 16,
											fontWeight: "400",
										}}>
										Submitting...
									</Text>
								</View>
							) : this.state.submitSuccess ? (
								<View
									style={{
										width: "100%",
									}}>
									<View
										style={{
											width: "100%",
											alignItems: "center",
											justifyContent: "center",
											// backgroundColor: "#FFF9FB",
											// position: "absolute",
											// top: 0,
										}}>
										<img
											src={require("../assets/top.png")}
											alt='Description'
											className='responsive'
										/>
										<View
											style={{
												width: "60%",
												position: "absolute",
												zIndex: 99,
												top: "60%",
											}}>
											<img
												src={
													require("../assets/tiktok.svg")
														.default
												}
												alt='Description'
												className='responsive'
											/>
										</View>
									</View>
									<View
										style={{
											marginBottom: 5,
											alignItems: "center",
											justifyContent: "center",
											width: "95%",
											marginBottom: 5,
											backgroundColor: "#FFF",
											// backgroundColor: '#F7F7F7',
											borderRadius: 20,
											padding: "5%",
											maxWidth: 500,
											marginRight: "auto",
											marginLeft: "auto",
											marginTop: 50,
										}}>
										<Text
											style={{
												color: "#000",
												fontSize: 25,
												marginBottom: 30,
												fontWeight: "800",
												marginTop: 50,
											}}>
											<Text>RSVP submitted!</Text>
										</Text>
										<Text
											style={{
												color: "#000",
												fontSize: 25,
												marginBottom: 30,
												fontWeight: "800",
												width: "100%",
												textAlign: "center",
											}}>
											Enjoy the event
										</Text>

										<TouchableOpacity
											style={{
												width: "100%",
												marginTop: 15,
												marginBottom: 40,
												backgroundColor: "#0A1526",
												borderRadius: 12,
												display:
													this.state.step === "4"
														? "flex"
														: "none",
											}}
											onPress={async () => {
												this.props.close();
											}}>
											<View
												style={{
													//  backgroundColor: APP_COLOURS.RED,
													width: "100%",
													height: 45,
													borderRadius: 25,
													justifyContent: "center",
													alignItems: "center",
												}}>
												<Text
													style={{
														alignItems: "center",
														justifyContent:
															"center",
														color: "#FFF",
														fontSize: 17,
													}}>
													<Text>Done</Text>
												</Text>
											</View>
										</TouchableOpacity>
									</View>
								</View>
							) : (
								<View
									style={{
										width: "100%",
										backgroundColor: "#FFF",
										// backgroundColor: '#F7F7F7',
										borderRadius: 20,
										padding: "5%",
										maxWidth: 500,
										marginRight: "auto",
										marginLeft: "auto",
										// marginTop: 50,
										// borderWidth: 1,
										// borderColor: "#BB9B75",
									}}>
									<View
										style={{
											display:
												this.state.step === "1"
													? "flex"
													: "none",
											width: "100%",
										}}>
										<View
											style={{
												marginBottom: 5,
												alignItems: "center",
												justifyContent: "center",
												width: "100%",
												marginBottom: 5,
											}}>
											<AutoHeightImage
												width={400}
												source={require("./assets/images/brand_logo.png")}
											/>
											<Text
												style={{
													color: "#000",
													fontSize: 25,
													marginBottom: 30,
													fontWeight: "800",
												}}>
												{/* <Text>{this.props.title}</Text> */}
												RSVP
											</Text>
											<Text
												style={{
													color: "#000",
													fontSize: 14,
													opacity: 0.8,
												}}>
												<Text>
													Please fill in all relevant
													fields
												</Text>
											</Text>
										</View>

										<View>
											<View
												style={{
													flex: 1,
													alignItems: "center",
													justifyContent: "center",
													//   marginBottom: 25,
													padding: 10,
												}}>
												<FullName
													first_name={(d) =>
														this.setState({
															first_name: d,
														})
													}
													last_name={(d) =>
														this.setState({
															last_name: d,
														})
													}
													name_title={(d) =>
														this.setState({
															title_name: d,
														})
													}
												/>
											</View>
										</View>

										<View
											style={{
												justifyContent: "center",
												// maxWidth: 280,
												// marginBottom: 25,
												width: "100%",
												padding: 10,
											}}>
											<View
												style={{
													padding: 15,
												}}>
												<Policy
													screenWidth={
														this.props.screenWidth
													}
													screenHeight={
														this.props.screenHeight
													}
													setParentState={(d) => {
														this.setState({
															policy: d,
														});
													}}
												/>
											</View>
											<View
												style={{
													padding: 15,
												}}>
												<OptIn
													screenWidth={
														this.props.screenWidth
													}
													screenHeight={
														this.props.screenHeight
													}
													setParentState={(d) => {
														this.setState({
															optin: d,
															emailFocus: true,
														});
													}}
												/>
												<View
													style={
														{
															// height: 10,
														}
													}
												/>
											</View>

											<View
												style={{
													padding: 10,
												}}>
												<Text
													style={{
														fontSize: 15,
														fontWeight: "400",
														width: "100%",
														textAlign: "center",
													}}>
													Select a preferred method of
													contact:
												</Text>
												<View
													style={{
														flexDirection: "row",
													}}>
													<TouchableOpacity
														style={[
															{
																flex: 1,
																// width: "80%",
																height: 48,
																borderRadius: 12,
																backgroundColor:
																	"#B1ACAA",
																alignItems:
																	"center",
																justifyContent:
																	"center",
																marginTop: 16,
															},
														]}
														onPress={() => {
															this.setState({
																preferredMethod:
																	"email",
																cell: "",
																email: "",
															});
														}}>
														<Text style={{}}>
															Email
														</Text>
													</TouchableOpacity>
													<View
														style={{
															width: 10,
														}}
													/>
													<TouchableOpacity
														style={[
															{
																flex: 1,
																height: 48,
																borderRadius: 12,
																backgroundColor:
																	"#B1ACAA",
																alignItems:
																	"center",
																justifyContent:
																	"center",
																marginTop: 16,
															},
														]}
														onPress={() => {
															this.setState({
																preferredMethod:
																	"cell",
																cell: "",
																email: "",
															});
														}}>
														<Text style={{}}>
															Cell
														</Text>
													</TouchableOpacity>
												</View>
											</View>

											{this.state.preferredMethod !==
											"" ? (
												<View
													style={{
														width: "100%",
														// marginBottom: 200,

														alignItems: "center",
														justifyContent:
															"center",
													}}>
													{this.state
														.preferredMethod ===
													"email" ? (
														<EmailInput
															textAlign={"center"}
															autoFocus={
																this.state
																	.emailFocus
															}
															setParentState={(
																d,
															) =>
																this.setState({
																	email: d,
																})
															}
														/>
													) : (
														<CellNumber
															title='Cell number'
															textAlign={"center"}
															autoFocus={
																this.state
																	.emailFocus
															}
															setParentState={(
																d,
															) =>
																this.setState({
																	email: d,
																})
															}
														/>
													)}
												</View>
											) : null}
										</View>

										<View
											style={{
												width: "100%",
											}}>
											<TouchableOpacity
												style={{
													// width: "100%",
													margin: 10,
													marginTop: 20,
													backgroundColor: "#0A1526",
													borderRadius: 12,
													display:
														this.state.email !==
															"" ||
														this.state.cell !== ""
															? this.state.policy
																? "flex"
																: "none"
															: "none",
												}}
												onPress={async () => {
													this.setState({
														step: "2",
													});
													this.scrollViewRef.scrollTo(
														{
															y: 0,
															x: 0,
															animated: true,
														},
													);
												}}>
												<View
													style={{
														//  backgroundColor: APP_COLOURS.RED,
														width: "100%",
														height: 45,
														borderRadius: 25,
														justifyContent:
															"center",
														alignItems: "center",
													}}>
													<Text
														style={{
															alignItems:
																"center",
															justifyContent:
																"center",
															color: "#FFF",
															fontSize: 17,
														}}>
														<Text>Next</Text>
													</Text>
												</View>
											</TouchableOpacity>
										</View>
									</View>

									<View
										style={{
											justifyContent: "center",
											marginBottom: 25,
											padding: 15,
											marginTop: 40,
											display:
												this.state.step === "2"
													? "flex"
													: "none",
										}}>
										<Text
											style={{
												color: "#000",
												fontSize: 19,
												fontWeight: "600",
												marginBottom: 15,
											}}>
											Do you have any special dietry
											requirements?
										</Text>
										<MultiSelect
											options={[
												{
													title: "None",
													option: 1,
												},
												{
													title: "Vegetarian",
													option: 2,
												},
												{
													title: "Vegan",
													option: 3,
												},
												{
													title: "Pescatarian",
													option: 4,
												},
												{
													title: "Gluten-Free",
													option: 5,
												},
												{
													title: "Lactose Intolerant",
													option: 6,
												},
												{
													title: "Nut Allergy",
													option: 7,
												},
												{
													title: "Halal",
													option: 8,
												},
												{
													title: "Kosher",
													option: 9,
												},
												{
													title: "Low Carb",
													option: 10,
												},
												{
													title: "Diabetic",
													option: 11,
												},
												{
													title: "Low Sodium",
													option: 12,
												},
												{
													title: "Low Fat/Fat-Free",
													option: 13,
												},
												{
													title: "Raw Food",
													option: 14,
												},
												{
													title: "Paleo",
													option: 15,
												},
												{
													title: "Keto",
													option: 16,
												},
												{
													title: "Whole30",
													option: 17,
												},
											]}
											submitAnswer={(d) =>
												this.setState({
													dietary: d,
												})
											}
										/>

										<TextInput
											style={{
												width: "100%",
												// paddingLeft: 40,
												// marginBottom: 5,
												padding: 10,
												marginTop: 20,
												fontSize: 17,
												borderRadius: 5,
												textAlign: "left",
												color: "#000",
												fontWeight: "300",
												fontFamily: "Avenir",
												letterSpacing: 0.26,
												borderWidth: 0.3,
												backgroundColor: "#FFF",
												borderColor: "#000",
											}}
											placeholderTextColor={"#797979"}
											autoCapitalize='none'
											placeholder={"Other"}
											value={this.state.answer_other}
											onChangeText={(text) => {
												this.setState({
													dietary_other: text,
												});
											}}
										/>
										<TouchableOpacity
											style={{
												width: "100%",
												// margin: 10,
												marginTop: 40,
												backgroundColor: "#0A1526",
												borderRadius: 12,
												display:
													this.state.dietary !== ""
														? "flex"
														: "none",
											}}
											onPress={async () => {
												this.setState({
													step: "3",
												});

												this.scrollViewRef.scrollTo({
													y: 0,
													x: 0,
													animated: true,
												});
											}}>
											<View
												style={{
													//  backgroundColor: APP_COLOURS.RED,
													width: "100%",
													height: 45,
													borderRadius: 25,
													justifyContent: "center",
													alignItems: "center",
												}}>
												<Text
													style={{
														alignItems: "center",
														justifyContent:
															"center",
														color: "#FFF",
														fontSize: 17,
													}}>
													<Text>Next</Text>
												</Text>
											</View>
										</TouchableOpacity>
									</View>

									<View
										style={{
											justifyContent: "center",
											marginBottom: 25,
											padding: 15,
											marginTop: 40,
											display:
												this.state.step === "3"
													? "flex"
													: "none",
										}}>
										<Text
											style={{
												color: "#000",
												fontSize: 19,
												fontWeight: "600",
												marginBottom: 15,
											}}>
											Select one of the options below that
											best represents your knowledge of
											Retinol?
										</Text>
										<MultiSelect
											options={[
												{
													title: "Uhmm, Whats retinol?",
													option: 1,
												},
												{
													title: "I've heard of retinol.",
													option: 2,
												},
												{
													title: "I've used retinol before.",
													option: 3,
												},
												{
													title: "I'd like to learn more about retinol.",
													option: 4,
												},
												{
													title: "I'm somewhat of a retinol expert.",
													option: 5,
												},
											]}
											submitAnswer={(d) =>
												this.setState({
													survey_q1: d,
												})
											}
										/>

										<TouchableOpacity
											style={{
												width: "100%",
												// margin: 10,
												marginTop: 40,
												backgroundColor: "#0A1526",
												borderRadius: 12,
												display:
													this.state.survey_q1 !== ""
														? "flex"
														: "none",
											}}
											onPress={async () => {
												// this.setState({
												// 	step: "3",
												// });
												this.submitForm();
											}}>
											<View
												style={{
													//  backgroundColor: APP_COLOURS.RED,
													width: "100%",
													height: 45,
													borderRadius: 25,
													justifyContent: "center",
													alignItems: "center",
												}}>
												<Text
													style={{
														alignItems: "center",
														justifyContent:
															"center",
														color: "#FFF",
														fontSize: 17,
													}}>
													<Text>Next</Text>
												</Text>
											</View>
										</TouchableOpacity>
									</View>

									<View
										style={{
											width: "100%",
											height: 50,
											// marginBottom: 12,
											justifyContent: "center",
											alignItems: "center",
											flexDirection: "row",
											// borderTopWidth: 0.5,
											opacity: 0.8,
											// position: 'absolute',
											// zIndex: 999,
											// bottom: 0,
											marginTop: 50,
										}}>
										<Text
											style={{
												fontSize: 11,
												color: "#141D31",
											}}>
											© {moment().format("YYYY")} TRAQ
											Powered by{" "}
										</Text>
										<Text
											style={{
												fontSize: 12,
												paddingTop: 1,
												color: "#141D31",
											}}>
											<Text
												style={{
													marginTop: 20,
													color: "#141D31",
												}}>
												<Text
													style={{
														//  fontFamily: 'Poppins',
														fontWeight: "900",
													}}>
													tetrice
												</Text>
											</Text>
										</Text>
									</View>
								</View>
							)}
						</ScrollView>
					</View>
				</KeyboardAvoidingView>
				{/* <View
          style={{
            height: this.state.keyboardOffset,
            // backgroundColor: '#797979',
          }}
        /> */}
			</View>
		);
	}

	setKeyboardListener = async () => {
		this.keyboardDidShowListener = Keyboard.addListener(
			"keyboardDidShow",
			this._keyboardDidShow,
		);
		this.keyboardDidHideListener = Keyboard.addListener(
			"keyboardDidHide",
			this._keyboardDidHide,
		);
	};

	_keyboardDidShow = (event) => {
		console.log(event.endCoordinates.height);
		if (this.state.keyboardOffset === 0) {
			let off = event.endCoordinates.height;
			this.setState({
				keyboardOffset: off,
			});
			this.onValueChange("keyboardOffset", JSON.stringify(off));
		}
	};

	_keyboardDidHide = () => {
		this.setState({
			keyboardActive: true,
		});
	};

	async onValueChange(item, selectedValue) {
		try {
			// await AsyncStorage.setItem(item, selectedValue).then((x) => {
			// console.log('onValueChange', item, selectedValue);
			// });
		} catch (error) {
			console.error(error);
		}
	}
}
