import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
import logo from "./logo.png";
// import logo_tetrice from "./logo_tetrice.png";
import nomobile from "./nomobile.png";
import tick from "./tick_red.png";
import traq_logo from "./traq_app_logo.svg";
// import traq_logo from "./traqbi.svg";
// import bg2 from "./bg2.svg";
import "./index.css";

import * as animationData from "./animation_ln08boza.json";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

class LoginPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			password: "",
			emailInput: "",
			emailValid: false,
			screenWidth: screenWidth,
			screenHeight: screenHeight,

			otpPinSent: false,
		};
	}

	componentDidMount() {
		console.log("componentDidMount");
		let accessToken = sessionStorage.getItem("accessToken");

		console.log("Approved User", accessToken);
		if (accessToken !== null) {
			// this.props.history.push("/dashboard/Home");
		} else {
			sessionStorage.clear();
			this.setState({
				loading: false,
			});
		}

		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	componentDidUpdate() {}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let contain =
			this.state.screenWidth * 0.9 > this.state.screenHeight * 0.9
				? this.state.screenHeight * 0.89
				: this.state.screenWidth * 0.9;

		// if (this.state.screenWidth < 700) {
		// 	return (
		// 		<div
		// 			style={{
		// 				backgroundColor: "#131119",
		// 				flex: 1,
		// 			}}>
		// 			<View
		// 				style={{
		// 					flex: 1,
		// 					alignItems: "center",
		// 					// marginTop:
		// 					height: this.state.screenHeight,
		// 					padding: 10,
		// 					justifyContent: "center",
		// 				}}>
		// 				<View
		// 					style={{
		// 						width: "100%",
		// 						maxWidth: 400,
		// 						alignItems: "center",
		// 						padding: 10,
		// 						borderRadius: 15,
		// 						backgroundColor: "#121419",

		// 						shadowColor: "#000",
		// 						shadowOffset: {
		// 							width: 0,
		// 							height: 8,
		// 						},
		// 						shadowOpacity: 0.44,
		// 						shadowRadius: 10.32,

		// 						elevation: 16,
		// 					}}>
		// 					<Image
		// 						source={logo}
		// 						style={{
		// 							width: 180,
		// 							height: 100,
		// 							resizeMode: "contain",
		// 						}}
		// 					/>

		// 					<View style={{}}>
		// 						<Image
		// 							source={nomobile}
		// 							style={{
		// 								width: 80,
		// 								height: 80,
		// 								resizeMode: "contain",
		// 								marginTop: 15,
		// 								marginBottom: 15,
		// 							}}
		// 						/>
		// 					</View>

		// 					<View
		// 						style={{
		// 							width: 300,
		// 							marginTop: 10,
		// 							marginBottom: 50,
		// 							opacity: 0.7,
		// 						}}>
		// 						<Text
		// 							style={{
		// 								color: "#FFF",
		// 								marginBottom: 15,
		// 								textAlign: "center",
		// 							}}>
		// 							TRAQ dashboard is not avaliable for smaller
		// 							mobile devices.
		// 						</Text>
		// 						<Text
		// 							style={{
		// 								color: "#FFF",
		// 								marginBottom: 15,
		// 								textAlign: "center",
		// 							}}>
		// 							Try using a tablet or desktop device.
		// 						</Text>
		// 					</View>
		// 				</View>
		// 			</View>
		// 		</div>
		// 	);
		// }

		return (
			<View
				style={{
					// flex: 1,
					width: this.state.screenWidth,
					height: this.state.screenHeight,
					backgroundColor: "#1F2229",
					overflow: "hidden",
				}}>
				<ScrollView showsVerticalScrollIndicator={false}>
					<View
						style={{
							// flex: 1,
							// alignItems: "center",
							// justifyContent: "center",
							justifyContent: "space-evenly",
						}}>
						<View
							style={{
								paddingLeft: "10%",
							}}>
							<Image
								source={traq_logo}
								style={{
									width: 200,
									height: 150,
									resizeMode: "contain",
								}}
							/>
							<Text
								style={{
									fontSize: 38,
									color: "#FFF",
									marginBottom: 30,
								}}>
								<Font family='Poppins' weight={800}>
									RSVP management
								</Font>
							</Text>
							<Text
								style={{
									fontSize: 20,
									color: "#FFF",
								}}>
								<Font family='Poppins' weight={400}>
									- Manage RSVPs all in one place
								</Font>
							</Text>
							<Text
								style={{
									fontSize: 20,
									color: "#FFF",
								}}>
								<Font family='Poppins' weight={400}>
									- Multiple events
								</Font>
							</Text>
							<Text
								style={{
									fontSize: 20,
									color: "#FFF",
								}}>
								<Font family='Poppins' weight={400}>
									- Track attendance
								</Font>
							</Text>
							<Text
								style={{
									fontSize: 20,
									color: "#FFF",
								}}>
								<Font family='Poppins' weight={400}>
									- Notifications & Reminders
								</Font>
							</Text>
						</View>
					</View>

					<View
						style={{
							flex: 1,
							flexDirection: "row",
							// width: this.state.screenWidth,
							// height: this.state.screenHeight,
						}}>
						{/* <View
						style={{
							flex: 1,
						}}>
						<View
							style={{
								position: "absolute",
								top: "5%",
								left: "2%",
							}}>
							<Image
								source={traq_logo}
								style={{
									width: 200,
									height: 100,
									resizeMode: "contain",
								}}
							/>
						</View>
					</View> */}
					</View>
					<View
						style={
							{
								// width: this.state.screenWidth,
								// height: this.state.screenHeight,
								// alignItems: "center",
								// justifyContent: "center",
							}
						}>
						{/* <View
							style={{
								width: this.state.screenWidth,
								height: this.state.screenHeight,
								right: 0,
								bottom: 0,
								position: "absolute",
							}}>
							<Image
								source={bg2}
								style={{
									width: maxContain * ar,
									height: maxContain,
									resizeMode: "contain",
									position: "absolute",
									left: -10,
									bottom: -10,
								}}
							/>
						</View> */}

						<View
							style={{
								// position: "absolute",
								bottom: "0%",
								right: "1%",
							}}>
							<Lottie
								options={defaultOptions}
								height={500}
								width={600}
							/>
						</View>
					</View>
				</ScrollView>

				<View
					style={{
						width: this.state.screenWidth,
						// position: "absolute",
						// bottom: 30,
						// left: 0,
						// padding: "10%",
						flexDirection: "row",
						justifyContent: "space-between",
						paddingRight: "10%",
						paddingLeft: "10%",
					}}>
					<View>
						<Text
							style={{
								// width: 70,
								fontSize: 16,
								// color: "#121419",
								color: "#FFF",
							}}>
							<Font family='Poppins' weight={800}>
								tetrice
							</Font>
						</Text>
						<Text
							style={{
								width: 110,
								fontSize: 13,
								color: "#FFF",
							}}>
							<Font family='Poppins' weight={300}>
								info@traq.app
							</Font>
						</Text>
					</View>
					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
						}}>
						<Image
							source={require("./google_play.png")}
							style={{
								width: 110,
								height: 32,
								resizeMode: "contain",
								marginRight: 10,
							}}
						/>

						<Image
							source={require("./app_store.png")}
							style={{
								width: 110,
								height: 32,
								resizeMode: "contain",
							}}
						/>
					</View>
				</View>
			</View>
		);
	}
}

export const withNavigation = (Component: Component) => {
	return (props) => <Component {...props} navigate={useNavigate()} />;
};

export default withNavigation(LoginPage);
