import React from "react";
import moment from "moment";
import {
	View,
	Text,
	StyleSheet,
	Image,
	ScrollView,
	TouchableOpacity,
} from "react-native";

const convertToCSV = (data) => {
	const csvRows = [];
	const headers = [
		"RSVP Event",
		"First Name",
		"Last Name",
		"Date Created",
		"Preferred Contact Method",
		"Contact",
		"Dietary",
		"Survey Question",
	];
	csvRows.push(headers.join(","));

	for (const item of data) {
		const row = [
			item.rsvp_event.toUpperCase(),
			item.first_name,
			item.last_name,
			moment(item.dateCreated).format("DD/MM/YYYY HH:mm"),
			item.preferredMethod,
			item.email || item.cell,
			item.dietary,
			item.survey_q1,
		];
		csvRows.push(row.join(","));
	}

	return csvRows.join("\n");
};

const downloadCSV = (csvString, fileName) => {
	const blob = new Blob([csvString], { type: "text/csv" });
	const link = document.createElement("a");
	link.href = URL.createObjectURL(blob);
	link.download = `${fileName}.csv`;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

const CsvExportButton = ({ dataParent, fileName }) => {
	const handleExport = () => {
		const csvString = convertToCSV(dataParent);
		downloadCSV(csvString, fileName);
	};

	return (
		<TouchableOpacity onPress={handleExport}>
			<View
				style={{
					// position: "absolute",
					// bottom: 0,
					width: "100%",
					padding: 10,
					backgroundColor: "green",
					justifyContent: "center",
					alignItems: "center",
				}}>
				<Text
					style={{
						fontSize: 16,
						color: "#FFF",
						fontWeight: "500",
					}}>
					Export to csv
				</Text>
			</View>
		</TouchableOpacity>
	);
};

export default CsvExportButton;
