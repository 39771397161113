import React, { Component } from "react";
import {
	View,
	Text,
	ActivityIndicator,
	Image,
	ScrollView,
	TouchableOpacity,
	Modal,
} from "react-native";
import Lottie from "react-lottie";
import Fade from "react-reveal/Fade";
import "./index.css";
// import FollowUs from "./Signup/FollowUs";
import * as animationData from "./animation_ln08boza.json";
import eln from "./assets/ea_l.svg";
import moment from "moment";
import RsvpForm from "./RSVPForm/ColorStayRSVP";
import card from "./ColorStayAssets/card.png";
import cardaccepted from "./ColorStayAssets/cardaccepted.png";

import b64ex from "./Inviteb64";

export default class ColorStayEvent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userData: "",
			eventTitle: "ColorStay™ @ Rockets Invite",
		};
	}

	componentDidMount() {
		// setTimeout(() => {
		// 	this.setState({
		// 		pageLoaded: true,
		// 	});
		// }, 2500);

		document.title = this.state.eventTitle;

		this.getLocalData();

		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	getLocalData = () => {
		const userData = JSON.parse(localStorage.getItem("userData"));
		console.log(userData);
		if (typeof userData !== "undefined" && userData !== null) {
			this.setState({
				userData: userData,
			});
		}
	};

	render() {
		return (
			<View
				style={{
					width: window.innerWidth,
					overflow: "hidden",
					height: window.innerHeight,
				}}>
				<Fade left>
					<View
						style={{
							position: "absolute",
							left: 0,
						}}>
						<Image
							source={require("./ColorStayAssets/group_left.png")}
							style={{
								width: 200,
								height: window.innerHeight,
								resizeMode: "cover",
							}}
						/>
					</View>
				</Fade>

				{this.state.rsvpForm ? this.rsvpForm() : null}
				{/* {this.loadingScreenInitial()} */}

				<Fade right>
					<View
						style={{
							position: "absolute",
							right: 0,
						}}>
						<Image
							source={require("./ColorStayAssets/group_right.png")}
							style={{
								width: 200,
								height: window.innerHeight,
								resizeMode: "cover",
							}}
						/>
					</View>
				</Fade>
				<ScrollView showsVerticalScrollIndicator={false}>
					<Fade>
						<View
							style={{
								position: "absolute",
								// left: this.state.screenWidth > 700 ? "30%" : "5%",
								width: "100%",
								alignItems:
									this.state.screenWidth > 500
										? "center"
										: "flex-start",

								margin: 10,
							}}>
							{this.state.userData === "" ? (
								<TouchableOpacity
									activeOpacity={0.8}
									onPress={() => {
										this.setState({
											rsvpForm: true,
										});
									}}>
									<Image
										source={card}
										style={{
											// width: window.in,
											// height: window.innerHeight * 0.8,
											height: window.innerHeight,
											width: window.innerWidth * 0.75,
											maxWidth: 350,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>
							) : (
								<Image
									source={cardaccepted}
									style={{
										// width: window.in,
										// height: window.innerHeight * 0.8,
										height: window.innerHeight,
										width: window.innerWidth * 0.75,
										maxWidth: 350,
										resizeMode: "contain",
									}}
								/>
							)}
						</View>
					</Fade>
				</ScrollView>

				{/* <View style={{
               width: '100%', padding: 10, 

            }} >

            </View> */}
			</View>
		);
	}

	loadingScreenInitial = () => {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};
		return (
			<Modal
				animationType='fade'
				transparent={true}
				visible={!this.state.pageLoaded}>
				<View
					style={{
						flex: 1,
						position: "absolute",
						zIndex: 999,
						// backgroundColor: "rgba(52, 52, 52, 1)",
						backgroundColor: "#FFF",
						width: window.innerWidth,
						height: window.innerHeight,
					}}>
					<View
						style={{
							width: "100%",
							alignItems: "center",
							justifyContent: "center",
							// backgroundColor: "#FFF9FB",
							position: "absolute",
							top: 0,
						}}>
						{/* <img
							src={require("./ColorStayAssets/colorbg.png")}
							alt='Description'
							className='responsive'
						/> */}
						<Image
							source={require("./ColorStayAssets/colorbg.png")}
							style={{
								width: window.innerWidth,
								height: window.innerHeight,
								resizeMode: "contain",
							}}
						/>
					</View>
					<View
						style={{
							justifyContent: "center",
							alignItems: "center",
							flex: 1,
						}}>
						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "#FFF",
								padding: 10,
								borderRadius: 10,
								paddingHorizontal: 20,
							}}>
							<Image
								source={require("./ColorStayAssets/revlon_colo.png")}
								style={{
									width: 200,
									height: 200,
									resizeMode: "contain",
								}}
							/>
							<ActivityIndicator color='#000' size={"small"} />
							<Text
								style={{
									fontSize: 16,
									fontWeight: "600",
									color: "#000",
									fontFamily: "Gotham",
									letterSpacing: -1,
								}}>
								LOADING INVITE
							</Text>
						</View>
					</View>
				</View>
			</Modal>
		);
	};

	rsvpForm = () => {
		return (
			<Modal
				animationType='fade'
				transparent={true}
				visible={true}
				onRequestClose={() => {
					this.setState({
						rsvpForm: !this.state.rsvpForm,
					});
					this.getLocalData();
				}}>
				<View
					style={{
						flex: 1,
						position: "absolute",
						zIndex: 999,
						// backgroundColor: "rgba(52, 52, 52, 1)",
						backgroundColor: "#FFF",
						width: window.innerWidth,
						height: window.innerHeight,
					}}>
					<RsvpForm
						title={this.state.eventTitle}
						close={() => {
							this.setState({
								rsvpForm: !this.state.rsvpForm,
							});

							this.getLocalData();
						}}
					/>
				</View>
			</Modal>
		);
	};
}
