import React, { Component } from "react";
import { View, TouchableOpacity, Image, Text } from "react-native";
import { APP_COLOURS } from "./APP_VARIABLES";

export default class MultiSelect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedOptions: [],
		};
	}

	toggleOption = async (option) => {
		const { selectedOptions } = this.state;
		const index = selectedOptions.indexOf(option.title);

		if (index === -1) {
			await this.setState({
				selectedOptions: [...selectedOptions, option.title],
			});
			this.props.submitAnswer(selectedOptions);
		} else {
			await this.setState({
				selectedOptions: [
					...selectedOptions.slice(0, index),
					...selectedOptions.slice(index + 1),
				],
			});
		}

		let allOptionsSelected = "";
		this.state.selectedOptions.forEach((e) => {
			allOptionsSelected = allOptionsSelected + ` ${e};`;
		});
		console.log(allOptionsSelected);
		this.props.submitAnswer(allOptionsSelected);
	};

	render() {
		const options = this.props.options;

		return (
			<View
				style={{
					width: "100%",
					// flexDirection: "row",
					flexWrap: "wrap",
				}}>
				{options.map((d, i) => (
					<View
						key={i}
						style={{
							flexDirection: "row",

							alignItems: "center",
							borderWidth: this.state.selectedOptions.includes(
								d.title,
							)
								? 0.5
								: 0,
							borderRadius: 10,
							padding: 10,
							marginBottom: 2,
							backgroundColor:
								this.state.selectedOptions.includes(d.title)
									? APP_COLOURS.TEXTWHITE
									: "transparent",
						}}>
						<TouchableOpacity
							style={{
								marginBottom: 4,
								flexDirection: "row",
								alignItems: "center",
								flexWrap: "wrap",
								width: "100%",
							}}
							onPress={() => this.toggleOption(d)}>
							{typeof d.img !== "undefined" && d.img !== "" ? (
								<Image
									source={d.img}
									style={{
										width: 100,
										height: 150,
										resizeMode: "cover",
									}}
								/>
							) : this.state.selectedOptions.includes(d.title) ? (
								<Image
									source={require("./assets/images/checked.png")}
									style={{
										width: 18,
										height: 18,
										resizeMode: "contain",
									}}
								/>
							) : (
								<Image
									source={require("./assets/images/unchecked.png")}
									style={{
										width: 18,
										height: 18,
										resizeMode: "contain",
									}}
								/>
							)}

							<View
								style={{
									flex: 1,
									flexWrap: "wrap",
								}}>
								<Text
									style={{
										alignItems: "center",
										justifyContent: "center",
										color: "#000",
										fontSize: 19,
										paddingLeft: 5,
										flexWrap: "wrap",
									}}>
									{d.title}
								</Text>
							</View>
						</TouchableOpacity>
					</View>
				))}
			</View>
		);
	}
}
