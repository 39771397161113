import React, { Component } from "react";
import {
	View,
	Text,
	ActivityIndicator,
	Image,
	ScrollView,
	TouchableOpacity,
	Modal,
} from "react-native";
import Lottie from "react-lottie";
import Fade from "react-reveal/Fade";
import "./index.css";
// import FollowUs from "./Signup/FollowUs";
import * as animationData from "./animation_ln08boza.json";
import RsvpForm from "./RSVPForm/ColorStayRSVP";
import card from "./ColorStayAssets/mainInvite.svg";
import card2 from "./ColorStayAssets/Group.png";
import moment from "moment";
import CountDownTimer from "./TimerCountdown";

export default class ColorStayReminder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userData: "",
			eventTitle: "ColorStay™ @ Rockets Countdown",
		};
	}

	componentDidMount() {
		// setTimeout(() => {
		// 	this.setState({
		// 		pageLoaded: true,
		// 	});
		// }, 2500);

		document.title = this.state.eventTitle;

		this.getLocalData();

		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	getLocalData = () => {
		const userData = JSON.parse(localStorage.getItem("userData"));
		console.log(userData);
		if (typeof userData !== "undefined" && userData !== null) {
			this.setState({
				userData: userData,
			});
		}
	};

	render() {
		return (
			<View
				style={{
					width: window.innerWidth,
					overflow: "hidden",
					height: window.innerHeight,
				}}>
				<Fade left>
					<View
						style={{
							position: "absolute",
							left: 0,
						}}>
						<Image
							source={require("./ColorStayAssets/group_left.png")}
							style={{
								width: 200,
								height: window.innerHeight,
								resizeMode: "cover",
							}}
						/>
					</View>
				</Fade>

				{this.state.rsvpForm ? this.rsvpForm() : null}
				{/* {this.loadingScreenInitial()} */}

				<Fade right>
					<View
						style={{
							position: "absolute",
							right: 0,
						}}>
						<Image
							source={require("./ColorStayAssets/group_right.png")}
							style={{
								width: 200,
								height: window.innerHeight,
								resizeMode: "cover",
							}}
						/>
					</View>
				</Fade>
				<View
					style={{
						width: window.innerWidth,
						overflow: "hidden",
						height: window.innerHeight,
						alignItems: "center",
						justifyContent: "center",
					}}>
					<View
						style={{
							position: "absolute",
							// left: this.state.screenWidth > 700 ? "30%" : "5%",
							width: "90%",
							maxWidth: 600,
							// alignItems:
							// 	this.state.screenWidth > 500
							// 		? "center"
							// 		: "flex-start",
							marginLeft: "auto",
							marginRight: "auto",
							alignItems: "center",

							backgroundColor: "#FFF",
							borderWidth: 10,
							borderColor: "#000",
							paddingBottom: 20,
						}}>
						<Image
							source={card2}
							style={{
								// width: window.in,
								// height: window.innerHeight * 0.8,
								height: 450,
								width: 300,
								maxWidth: 350,
								resizeMode: "contain",
							}}
						/>
						<Text
							style={{
								fontSize: 19,
								fontWeight: "Bold",
								color: "#000",
								fontFamily: "Avenir",
							}}>
							Countdown
						</Text>
						<CountDownTimer />
					</View>
				</View>
			</View>
		);
	}

	loadingScreenInitial = () => {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};
		return (
			<Modal
				animationType='fade'
				transparent={true}
				visible={!this.state.pageLoaded}>
				<View
					style={{
						flex: 1,
						position: "absolute",
						zIndex: 999,
						// backgroundColor: "rgba(52, 52, 52, 1)",
						backgroundColor: "#FFF",
						width: window.innerWidth,
						height: window.innerHeight,
					}}>
					<View
						style={{
							width: "100%",
							alignItems: "center",
							justifyContent: "center",
							// backgroundColor: "#FFF9FB",
							position: "absolute",
							top: 0,
						}}>
						{/* <img
							src={require("./ColorStayAssets/colorbg.png")}
							alt='Description'
							className='responsive'
						/> */}
						<Image
							source={require("./ColorStayAssets/colorbg.png")}
							style={{
								width: window.innerWidth,
								height: window.innerHeight,
								resizeMode: "contain",
							}}
						/>
					</View>
					<View
						style={{
							justifyContent: "center",
							alignItems: "center",
							flex: 1,
						}}>
						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "#FFF",
								padding: 10,
								borderRadius: 10,
								paddingHorizontal: 20,
							}}>
							<Image
								source={require("./ColorStayAssets/revlon_colo.png")}
								style={{
									width: 200,
									height: 200,
									resizeMode: "contain",
								}}
							/>
							<ActivityIndicator color='#000' size={"small"} />
							<Text
								style={{
									fontSize: 16,
									fontWeight: "600",
									color: "#000",
									fontFamily: "Gotham",
									letterSpacing: -1,
								}}>
								LOADING INVITE
							</Text>
						</View>
					</View>
				</View>
			</Modal>
		);
	};
}
