// App.js

import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet } from "react-native";
import moment from "moment";

export default function CountDownTimer() {
	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);

		return () => clearInterval(timer);
	}, []);

	function calculateTimeLeft() {
		const targetDate = moment("26/10/2023 15:00", "DD/MM/YYYY HH:mm");
		const now = moment();
		const diff = targetDate.diff(now);

		if (diff <= 0) {
			return {
				days: 0,
				hours: 0,
				minutes: 0,
				seconds: 0,
			};
		}

		return {
			days: Math.floor(moment.duration(diff).asDays()),
			hours: moment.duration(diff).hours(),
			minutes: moment.duration(diff).minutes(),
			seconds: moment.duration(diff).seconds(),
		};
	}

	return (
		<View style={styles.container}>
			<Text style={styles.timer}>
				{timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m{" "}
				{timeLeft.seconds}s
			</Text>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		justifyContent: "center",
		alignItems: "center",
	},
	timer: {
		fontSize: 30,
		fontWeight: "900",
		color: "#E8202C",
		// fontFamily: "Avenir",
	},
});
