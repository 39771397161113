import React, { Component } from "react";
import {
	View,
	Text,
	ActivityIndicator,
	TouchableOpacity,
	ScrollView,
	SafeAreaView,
	TextInput,
	Platform,
	UIManager,
	LayoutAnimation,
	Keyboard,
	TouchableWithoutFeedback,
	Animated,
	Easing,
	Image,
} from "react-native";
import AutoHeightImage from "../AutoHeightImageComp";
import moment from "moment";
import {
	APP_COLOURS,
	screenHeight,
	screenWidth,
	CustomLayoutSpring,
} from "../APP_VARS";
// import {api_chatmessages, api_getChatMessages} from '../Api';
import {
	crudUpdate,
	crudCreate,
	crudReadAll,
	sendJarvisMessage,
	sendSingleJarvisMessage,
} from "../CRUDHELPER";

export default class JarvisChat extends Component {
	constructor(props) {
		super(props);
		this.state = {
			messages: [],
			keyboardActive: false,
			keyboardOffset: 0,

			messageData: "",
			messageDataType: "text",
			beginScroll: false,
			secondsCountdown: 10,

			bottomofScrollview: 0,
			listeningForMessage: false,
		};

		// this.keyboardOffset = new Animated.Value(0);
		// this.inputRef = React.createRef();
		// this.inputInterval = 500;
		// this.messageData = null;
	}

	async componentDidMount() {
		// await this.getMessages();
		await clearInterval(this.interval);
		this.interval = setInterval(
			() =>
				this.setState({
					secondsCountdown: this.state.secondsCountdown - 1,
				}),
			1000,
		);
		console.log("mounted");
	}

	triggerTimer = async () => {
		//  return;
		console.log("triggerTimer");
		await clearInterval(this.interval);
		await this.setState({
			secondsCountdown: 10,
		});

		this.interval = await setInterval(
			() =>
				this.setState({
					secondsCountdown: this.state.secondsCountdown - 1,
				}),
			1000,
		);
	};

	async componentDidUpdate(prevProps, prevState) {
		if (prevState.secondsCountdown === 1) {
			await console.log("fetch messages");
			await this.setState({
				secondsCountdown: 0,
			});
			await clearInterval(this.interval);
			// await this.getMessages();
		}
		console.log(this.state.secondsCountdown);
	}

	async componentWillUnmount() {
		await console.log("unmounted");
		await clearInterval(this.interval);

		this.setState = (state, callback) => {
			return;
		};
	}

	getMessages = async () => {
		await clearInterval(this.interval);
		crudReadAll("pm_jarvis_chats", async (s, d) => {
			console.log(d, s);
			await this.setState({
				messages: d,
				loading: false,
				sendingMessage: false,
			});

			//   this.onValueChange(
			//     this.props.chat_id,
			//     JSON.stringify(responseData),
			//   );
		});

		await this.triggerTimer();
	};

	s_SingleJarvisMessage = async () => {
		let now = moment().toISOString();

		let message = {
			text: this.messageData,
			from: "Wesley",
			date: now,
		};

		// TTS.speak('Good day Sir!', {
		//   iosVoiceId: 'com.apple.ttsbundle.Samantha-compact',
		//   rate: 0.52,
		// });

		// return;

		// let message = {
		//   text: 'How long until chrsitmas',
		//   from: 'Wesley',
		//   sent: now,
		// };
		await clearInterval(this.interval);
		await this.setState({
			sendingMessage: true,
			messageData: this.messageData,
		});
		let messages = this.state.messages;
		messages = [...this.state.messages, message];

		this.setState({
			messages,
		});

		this.scrollView.scrollToEnd({ animated: true });
		// this.getMessages();
		await this.scrollView.scrollToEnd({ animated: true });
		await this.inputRef.clear();
		await this.setState({
			messageData: "",
			sendingMessage: false,
		});
		this.scrollView.scrollToEnd({ animated: true });

		let messageString = "";
		const mm = await messages.map(
			(m) =>
				(messageString = `${messageString} ${m.date} ${m.from}: ${m.text};`),
		);
		this.setState({
			listeningForMessage: true,
		});
		console.log(messageString);
		// return;
		sendSingleJarvisMessage(messageString, async (s, d) => {
			if (s === 200 && d.length > 0) {
				messages = [...messages, d[0]];
				console.log(s, d);
				// TTS.speak(d[0].text, {
				//   iosVoiceId: 'com.apple.ttsbundle.Daniel-compact',
				//   name: 'Samantha',
				//   language: 'en-GB',
				//   rate: 0.55,
				// });
				this.setState({
					messages,
					listeningForMessage: false,
				});
			}
			// this.scrollView.scrollToEnd({animated: true});
		});
	};

	sendMessage = async () => {
		let now = moment().toISOString();
		//  let message = {
		//    collection: 'pm_jarvis_chats',
		//    data: {
		//      text: this.messageData,
		//      from: 'Wesley',
		//      sent: now,
		//    },
		//  };

		let message = {
			text: this.messageData,
			from: "Wesley",
			sent: now,
		};
		await clearInterval(this.interval);
		await this.setState({
			sendingMessage: true,
			messageData: this.messageData,
		});
		this.scrollView.scrollToEnd({ animated: true });
		this.inputRef.clear();
		// return;
		crudCreate("pm_jarvis_chats", message, async () => {
			sendJarvisMessage();
			this.scrollView.scrollToEnd({ animated: true });
			this.getMessages();
			await this.scrollView.scrollToEnd({ animated: true });
			await this.inputRef.clear();
			await this.setState({
				messageData: "",
			});
			this.scrollView.scrollToEnd({ animated: true });
		});
	};

	render() {
		//  let d = this.props.data;
		LayoutAnimation.configureNext(CustomLayoutSpring);

		let messages = this.state.messages.sort(
			(a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
		);
		return (
			<View
				style={{
					flex: 1,
					backgroundColor: APP_COLOURS.BACKGROUND,
					paddingTop: 50,
					paddingBottom: 17,
					height: window.innerHeight,
				}}>
				<View
					style={{
						width: "100%",
						justifyContent: "space-between",
						alignItems: "center",
						flexDirection: "row",
						padding: 5,
						marginBottom: 5,
						backgroundColor: APP_COLOURS.BACKGROUND,
					}}>
					<View
						style={{
							width: "100%",
							// height: 60,
							justifyContent: this.state.keyboardActive
								? "flex-end"
								: "center",
							position: "absolute",
							alignItems: this.state.keyboardActive
								? "flex-end"
								: "center",
							paddingLeft: 5,
							backgroundColor: APP_COLOURS.BACKGROUND,
						}}>
						{/* <AutoHeightImage
							width={100}
							source={require("../assets/images/logo.png")}
						/> */}
					</View>
					<TouchableOpacity onPress={() => {}}>
						<View
							style={{
								width: 35,
								height: 35,
								borderRadius: 8,
								justifyContent: "center",
								alignItems: "center",

								backgroundColor: APP_COLOURS.WHITE_TRANSPARENT4,
							}}>
							{/* <AutoHeightImage
								width={13}
								source={require("../assets/images/back.png")}
							/> */}
						</View>
					</TouchableOpacity>
				</View>

				<View
					style={{
						height: this.state.keyboardActive ? 1 : 60,
						borderBottomWidth: 0.5,
						borderBottomColor: "#333A46",
					}}
				/>

				<View
					style={{
						flexDirection: "row",
						backgroundColor: APP_COLOURS.BACKGROUND,
						shadowColor: APP_COLOURS.BACKGROUND,
						shadowOffset: { width: 0, height: 8 },
						shadowOpacity: 0.14,
						shadowRadius: 9.32,
						elevation: 12,
						top: this.state.keyboardActive ? 48 : 100,
						position: "absolute",
					}}>
					<View
						style={{
							// flex: 1,
							flexDirection: "row",
							alignItems: "center",
							// paddingRight: 5,
						}}>
						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
								margin: 5,
								borderRadius: 30,
								borderWidth: 2,
								padding: 0.5,
								borderColor: "online",
								overflow: "hidden",
								marginRight: 9,
							}}>
							{/* {Avatars.filter(
								(av) => av.avatar === parseInt(3),
							).map((d_, ii) => (
								<AutoHeightImage
									key={ii}
									width={40}
									source={d_.pp}
									style={{
										resizeMode: "cover",
										borderRadius: 25,
									}}
								/>
							))} */}
							{this.state.listeningForMessage ? (
								<View
									style={{
										padding: 10,
										justifyContent: "center",
										alignItems: "center",
										position: "absolute",
										top: 0,
										left: 0,
									}}>
									<ActivityIndicator
										color={APP_COLOURS.YELLOW}
									/>
								</View>
							) : null}
						</View>

						<View
							style={
								{
									// flex: 1,
								}
							}>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									//   marginBottom: 3,
								}}>
								<Text
									allowFontScaling={false}
									style={{
										fontWeight: "700",
										fontSize: 15,
										color: "#FFF",
									}}>
									Jarvis
								</Text>
							</View>
							{/* <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                <Text
                  allowFontScaling={false}
                  style={{
                    fontWeight: '200',
                    fontSize: 10,
                    color: '#FFF',
                  }}>
                  Online
                  <Text
                    allowFontScaling={false}
                    style={{
                      fontWeight: '200',
                      opacity: 0.4,
                    }}>
                    {moment().fromNow()}
                  </Text>
                </Text>
              </View> */}
						</View>
					</View>

					{/* <View style={{flex: 1}}>
            <TouchableOpacity
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onPress={async () => {
                sendJarvisMessage();
                this.scrollView.scrollToEnd({animated: true});
                this.getMessages();
                await this.scrollView.scrollToEnd({animated: true});
                await this.inputRef.clear();
                await this.setState({
                  messageData: '',
                });
                this.scrollView.scrollToEnd({animated: true});
              }}>
              <Image
                style={{
                  width: 35,
                  height: 35,
                  resizeMode: 'contain',
                  // paddingLeft: 10,
                  transform: [{rotate: this.state.expand ? '90deg' : '180deg'}],
                }}
                source={require('../assets/images/expand.png')}
              />
            </TouchableOpacity>
          </View> */}
				</View>

				<Animated.View
					style={{
						flex: 1,
						backgroundColor: APP_COLOURS.WHITE_TRANSPARENT4,

						// height: this.state.keyboardActive ? screenHeight - this.state.keyboardOffset - 260,
					}}>
					<ScrollView
						ref={(ref) => (this.scrollView = ref)}
						// onScrollBeginDrag={() =>
						//   this.setState({
						//     beginScroll: true,
						//   })
						// }
						// onScrollEnd={() =>
						//   this.setState({
						//     beginScroll: false,
						//   })
						// }
						// onScrollBeginDrag={() => Keyboard.dismiss()}
						onContentSizeChange={() =>
							this.scrollView.scrollToEnd({ animated: true })
						}
						scrollEventThrottle={16}
						onScroll={(e) => {
							//   // let this.state.coords
							let yPos = e.nativeEvent.contentOffset.y;

							if (
								yPos >=
									this.state.bottomofScrollview -
										screenHeight &&
								this.state.beginScroll
							) {
								this.setState({
									beginScroll: false,
								});
							}

							//   Animated.parallel([
							//     Animated.spring(this.scrollPosition, {
							//       toValue: yPos,
							//       useNativeDriver: false,
							//       friction: 12,
							//     }),
							//     Animated.spring(this.isScrolling, {
							//       toValue: 1,
							//       useNativeDriver: false,
							//       friction: 12,
							//     }),
							//     Animated.spring(this.yOffset, {
							//       toValue: Math.round(progresss),
							//       useNativeDriver: false,
							//       friction: 12,
							//     }),
							//   ]).start();
						}}>
						<TouchableWithoutFeedback
							onPress={() => {
								Keyboard.dismiss();
								this.setState({ keyboardActive: false });
							}}>
							<View
								onLayout={(e) => {
									console.log();
									this.setState({
										bottomofScrollview:
											e.nativeEvent.layout.height,
									});
								}}
								style={{
									flex: 1,
									width: "100%",
									padding: 10,
									paddingRight: 10,
									paddingLeft: 20,
								}}>
								{messages.map((d, i) => {
									return (
										<TouchableOpacity onPress={() => {}}>
											<View
												key={`js-${i}`}
												style={{
													width: "100%",
													zIndex: 10,
													alignItems:
														d.from ==
														this.props.user_id
															? "flex-end"
															: "flex-start",
												}}>
												<View
													style={{
														maxWidth: "75%",
														flexDirection:
															d.length > 20 ||
															d.from !== "Jarvis"
																? "column"
																: "row",
														backgroundColor:
															d.from === "Jarvis"
																? APP_COLOURS.WHITE_TRANSPARENT
																: APP_COLOURS.BLUE2,
														borderRadius: 16,
														borderTopLeftRadius:
															d.from === "Jarvis"
																? 16
																: 3,
														borderBottomLeftRadius:
															d.from === "Jarvis"
																? 16
																: typeof messages[
																		i + 1
																  ] !==
																  "undefined"
																? messages[
																		i + 1
																  ].from ===
																  messages[i]
																		.from
																	? 3
																	: 16
																: 16,
														borderTopRightRadius:
															d.from === "Jarvis"
																? typeof messages[
																		i - 1
																  ] !==
																  "undefined"
																	? messages[
																			i -
																				1
																	  ].from ===
																	  messages[
																			i
																	  ].from
																		? 3
																		: 16
																	: 3
																: 16,
														borderBottomRightRadius:
															d.from ===
															this.props.user_id
																? 2
																: 16,
														padding: 10,
														marginTop:
															i > 0
																? messages[
																		i - 1
																  ].from ===
																  messages[i]
																		.from
																	? 3
																	: 15
																: 3,
														shadowColor: "#000",
														shadowOffset: {
															width: 0,
															height: 8,
														},
														shadowOpacity:
															d.from ===
															this.props.user_id
																? 0
																: 0.24,
														shadowRadius: 9.32,
														elevation: 10,
														// opacity: 0.9,
													}}>
													<Text
														style={{
															color: "#FFF",
															fontSize: 17,
														}}>
														{typeof d.text !==
														"undefined"
															? d.text
															: d.data}
													</Text>
													<Text
														style={{
															fontWeight: "300",
															fontStyle: "italic",
															marginLeft: 10,
															color: "#FFF",
															fontSize: 10,
															alignSelf:
																"flex-end",
															marginTop: 2,
															opacity: 0.7,
														}}>
														{moment().diff(
															d.createdAt,
															"days",
														) >= 1
															? moment(
																	d.createdAt,
															  ).format(
																	"HH:mm MMM DD, YYYY",
															  )
															: moment(
																	d.createdAt,
															  ).format("HH:mm")}
													</Text>
													{/* <Image
                          source={require('../assets/images/Checkmark.png')}
                          style={{
                            width: 12,
                            height: 12,
                            resizeMode: 'contain',
                            position: 'absolute',
                            right: 0,
                            bottom: -12,
                            display:
                              d.from === this.props.user_id ? 'flex' : 'none',
                          }}
                        /> */}
												</View>
											</View>
										</TouchableOpacity>
									);
								})}

								{this.state.sendingMessage ? (
									<View
										style={{
											width: "100%",
											zIndex: 10,
											alignItems: "flex-end",
											marginTop: 3,
											//   opacity: 0.3,
										}}>
										<View
											style={{
												maxWidth: "70%",
												// flexDirection:
												//   this.state.messageData.length > 20 ? 'column' : 'row',
												backgroundColor:
													APP_COLOURS.BLUE2,
												borderRadius: 16,
												borderBottomRightRadius: 2,
												padding: 12,
												marginBottom: 15,
												shadowColor: "#000",
												shadowOffset: {
													width: 0,
													height: 8,
												},
												shadowOpacity: 0.14,
												shadowRadius: 9.32,
												elevation: 10,
												// opacity: 0.9,
											}}>
											<Text
												style={{
													color: "#FFF",
												}}>
												{this.state.messageData}
											</Text>
											<Text
												style={{
													fontWeight: "300",
													fontStyle: "italic",
													marginLeft: 10,
													color: "#FFF",
													fontSize: 8,
													alignSelf: "flex-end",
													//  marginTop: 15,
												}}>
												<ActivityIndicator
													color={"#FFF"}
													size='small'
												/>
											</Text>
										</View>
									</View>
								) : null}
							</View>
						</TouchableWithoutFeedback>
						<View
							style={{
								height: 20,
							}}
						/>
					</ScrollView>
					<View
						style={{
							zIndex: 999,
							position: "absolute",
							bottom: 50,
							right: 0,
							paddingRight: 6,
							borderTopLeftRadius: 10,
							borderBottomLeftRadius: 10,
							backgroundColor: APP_COLOURS.BG2,

							shadowColor: "#000",
							shadowOffset: { width: 0, height: 8 },
							shadowOpacity: 0.14,
							shadowRadius: 9.32,
							elevation: 10,
							right: this.state.beginScroll ? 0 : -100,

							opacity: 0.85,
						}}>
						<View
							style={
								{
									// alignItems: 'center',
								}
							}>
							<TouchableOpacity
								style={{
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "center",
								}}
								onPress={() => {
									this.scrollView.scrollToEnd({
										animated: true,
									});
									this.setState({
										beginScroll: false,
									});
								}}>
								{/* <Image
									style={{
										width: 35,
										height: 35,
										resizeMode: "contain",
										// paddingLeft: 10,
										transform: [
											{
												rotate: this.state.expand
													? "90deg"
													: "0deg",
											},
										],
									}}
									source={require("../assets/images/expand.png")}
								/> */}
							</TouchableOpacity>
						</View>
					</View>
				</Animated.View>

				<View
					style={{
						padding: this.state.keyboardActive ? 3 : 10,
						// backgroundColor: APP_COLOURS.BACKGROUND_FADE,
						borderTopWidth: this.state.keyboardActive ? 0.5 : 0,
						borderTopColor: "#333A46",
					}}>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							marginBottom: 0,
							paddingBottom: this.state.keyboardActive ? 0 : 15,
						}}>
						<View
							style={{
								flex: 1,
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "flex-end",
								paddingLeft: 5,
								marginTop: 5,
								paddingRight: 10,
							}}>
							{/* {this.state.keyboardActive ? null : (
								<TouchableOpacity
									onPress={async () => {
										alert(
											"No images can be added at this time",
										);
									}}>
									<AutoHeightImage
										width={20}
										source={require("../assets/images/plus.png")}
									/>
								</TouchableOpacity>
							)} */}

							<TextInput
								ref={(ref) => (this.inputRef = ref)}
								style={{
									flex: 1,
									paddingLeft: 15,
									maxHeight: 300,
									padding: 12,
									paddingTop: 12,
									fontSize: 15,
									borderRadius: 13,
									marginRight: 15,
									marginLeft: this.state.keyboardActive
										? 3
										: 15,
									color: "#FFF",
									backgroundColor:
										APP_COLOURS.WHITE_TRANSPARENT5,
									fontWeight: "300",
								}}
								multiline
								numberOfLines={4}
								placeholderTextColor={"#828282"}
								clearButtonMode='while-editing'
								autoCompleteType='off'
								autoCorrect={true}
								autoCapitalize='sentences'
								placeholder={" Type message"}
								onFocus={async () => {
									await this.setState({
										keyboardActive: true,
										beginScroll: false,
									});

									setTimeout(() => {
										this.scrollView.scrollToEnd({
											animated: true,
										});
										this.scrollView.scrollToEnd({
											animated: true,
										});
									}, 600);
								}}
								onBlur={() =>
									this.setState({
										keyboardActive: false,
									})
								}
								// value={this.state.messageData}
								onChangeText={(text) => {
									// this.setState({
									//   messageData: text,
									//   messageDataType: 'text',
									// });
									// clearInterval(this.interval);
									// clearTimeout(this.inputInterval);
									// clearInterval(this.inputInterval);
									// this.inputInterval = setTimeout(() => {
									//   this.triggerTimer();
									// }, 1000);

									this.messageData = text;
								}}
								// onSubmitEditing={() => this.addMeeting()}
							/>

							<TouchableOpacity
								disabled={this.state.sendingMessage}
								style={{
									opacity:
										this.state.filterSearch !== ""
											? 1
											: 0.5,
								}}
								onPress={async () => {
									// await this.sendMessage();
									this.s_SingleJarvisMessage();
								}}>
								<View
									style={{
										width: 40,
										height: 40,
										justifyContent: "center",
										alignItems: "center",
									}}>
									{/* {this.state.sendingMessage ? (
										<ActivityIndicator
											color={APP_COLOURS.BLUE2}
											size='small'
										/>
									) : (
										<AutoHeightImage
											width={20}
											source={require("../assets/images/send.png")}
										/>
									)} */}
								</View>
							</TouchableOpacity>
						</View>
					</View>
				</View>
				{Platform.OS === "android" ? null : (
					<Animated.View
						style={{
							//   height: this.keyboardOffset,
							height: this.state.keyboardActive
								? this.state.keyboardOffset - 10
								: 0,
							// backgroundColor: 'red',
							justifyContent: "center",
							alignItems: "center",
						}}
					/>
				)}
				<View
					style={{
						position: "absolute",
						bottom: 20,
						alignItems: "center",
						marginTop: 5,
						justifyContent: "center",
					}}>
					<Text
						allowFontScaling={false}
						style={{
							backgroundColor: "transparent",
							color: "#666666",
							fontSize: 9,
							fontStyle: "normal",
							fontWeight: "300",
							textAlign: "center",
							width: screenWidth,
						}}>
						© {moment().format("YYYY")}{" "}
						<Text
							style={{
								fontWeight: "800",
							}}>
							tetrice
						</Text>{" "}
						pty ltd
					</Text>
				</View>
			</View>
		);
	}

	_keyboardDidShow = (event) => {
		console.log(event.endCoordinates.height);
		if (this.state.keyboardOffset === 0) {
			let off = event.endCoordinates.height;
			this.setState({
				keyboardOffset: off,
			});
			this.onValueChange("keyboardOffset", JSON.stringify(off));
		}

		//  Animated.timing(this.keyboardOffset, {
		//    toValue: event.endCoordinates.height,
		//    duration: 60,
		//    easing: Easing.linear,
		//    useNativeDriver: false,
		//  }).start();
	};

	_keyboardDidHide = () => {
		//  Animated.timing(this.keyboardOffset, {
		//    toValue: 0,
		//    duration: 60,
		//    easing: Easing.linear,
		//    useNativeDriver: false,
		//  }).start();
	};
}
