import React, { Component } from "react";
import {
	View,
	Text,
	ActivityIndicator,
	Image,
	ScrollView,
	TouchableOpacity,
	Modal,
} from "react-native";
import Lottie from "react-lottie";
import Fade from "react-reveal/Fade";
import "./index.css";
// import FollowUs from "./Signup/FollowUs";
import * as animationData from "./animation_ln08boza.json";
import eln from "./assets/ea_l.svg";
import moment from "moment";
import RsvpForm from "./RSVPForm/RsvpForm";

export default class ElanRetinolPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageLoaded: false,
			redirectUrl:
				"https://www.edgars.co.za/eight-hour-cream-skin-protectant-8501366301?gclid=Cj0KCQjwldKmBhCCARIsAP-0rfx2mNSCYRMq9JjNN05lDblNYSnpmuLcWOiC0gyTSxDY2uUVJT2rmkwaAkobEALw_wcB",

			rsvpForm: false,
			eventTitle: "Retinol - TikTok Master Class",

			eventDate: "17/10/2023",

			userData: "",

			screenWidth: window.innerWidth,
		};
	}

	componentDidMount() {
		document.title = this.state.eventTitle;

		setTimeout(() => {
			this.setState({
				pageLoaded: true,
			});
		}, 2500);

		this.getLocalData();
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	getLocalData = () => {
		const userData = JSON.parse(localStorage.getItem("userData"));
		console.log(userData);
		if (typeof userData !== "undefined" && userData !== null) {
			this.setState({
				userData: userData,
			});
		}
	};

	render() {
		let height = window.innerHeight * 0.5;
		let width = window.innerWidth;
		let isPhone = width < 950;

		width = isPhone ? width : width / 2;
		width = width > 500 ? 500 : width;
		return (
			<View
				style={{
					justifyContent: "center",
					alignItems: "center",
					width: window.innerWidth,
					height: window.innerHeight,
					marginLeft: "auto",
					marginRight: "auto",
					// backgroundColor: "#FDFAFB",
					backgroundColor: "#F8DADF",
				}}>
				<View
					style={{
						// flex: 1,
						justifyContent: "center",
						alignItems: "center",
						width: window.innerWidth,
						height: window.innerHeight,
						marginLeft: "auto",
						marginRight: "auto",

						backgroundColor: "#F8DADF",
						maxWidth: 500,
						opacity: this.state.pageLoaded ? 1 : 0,
					}}>
					{/* <View
						style={{
							width: "100%",
							position: "absolute",
							zIndex: 1,
							bottom: 0,
						}}>
						<img
							src={require("./assets/Rectangle.svg").default}
							alt='Description'
							className='responsive'
						/>
					</View> */}

					<View
						style={{
							width: "100%",
							alignItems: "center",
							justifyContent: "center",
							// backgroundColor: "#FFF9FB",
							position: "absolute",
							top: 0,
						}}>
						<img
							src={require("./assets/top.png")}
							alt='Description'
							className='responsive'
						/>
						<View
							style={{
								width: "60%",
								position: "absolute",
								zIndex: 99,
								top: "60%",
							}}>
							<img
								src={require("./assets/tiktok.svg").default}
								alt='Description'
								className='responsive'
							/>
						</View>
					</View>
					<ScrollView
						showsVerticalScrollIndicator={false}
						style={{
							zIndex: 2,
						}}>
						<View
							style={{
								width: "100%",
								// flexDirection: isPhone ? "column" : "row",
								maxWidth: 500,
								marginLeft: "auto",
								marginRight: "auto",
								zIndex: 2,
								// marginTop: 180,
								// marginBottom: 100,
							}}>
							<View
								style={{
									width: "100%",
									alignItems: "center",
									justifyContent: "center",
									backgroundColor: "#FFF9FB",
									opacity: 0,
									marginBottom: 20,
									// marginBottom: 100,
								}}>
								<img
									src={require("./assets/top.png")}
									alt='Description'
									className='responsive'
								/>
								<View
									style={{
										width: "60%",
										position: "absolute",
										zIndex: 99,
										top: "60%",
									}}>
									<img
										src={
											require("./assets/tiktok.svg")
												.default
										}
										alt='Description'
										className='responsive'
									/>
								</View>
							</View>

							<View
								style={{
									flex: 1,
									backgroundColor: "#FFF9FB",
									borderRadius: 15,
									paddingBottom: 100,
									// top: 50,
								}}>
								<View
									style={{
										width: "100%",
										alignItems: "center",
										justifyContent: "center",
										marginLeft: "auto",
										marginRight: "auto",
										marginTop: "10%",
										flexDirection: "row",
									}}>
									{/* <img
										src={
											require("./assets/ea_l.svg").default
										}
										alt='Description'
										className='responsive'
									/> */}
									<Text
										style={{
											fontFamily: "BauerBodoni",
											color: "#DC8699",
											fontSize: 40,
											marginVertical: 2,
											textAlign: "center",
											letterSpacing: "5%",
										}}>
										Elizabeth Arden
									</Text>
								</View>

								<View
									style={{
										width: "40%",
										alignItems: "center",
										justifyContent: "center",
										marginLeft: "auto",
										marginRight: "auto",
										marginBottom: 19,
									}}>
									{/* <img
										src={
											require("./assets/elan.svg").default
										}
										alt='Description'
										className='responsive'
									/> */}
									<Text
										style={{
											fontFamily: "Gotham",
											color: "#DC8699",
											fontSize: 29,
											// marginVertical: 2,
											textAlign: "center",
											letterSpacing: "5%",
										}}>
										EL&N
									</Text>
									<Text
										style={{
											fontFamily: "Gotham",
											color: "#DC8699",
											fontSize: 16,
											marginVertical: 2,
											textAlign: "center",
										}}>
										Mall of Africa
									</Text>
								</View>

								<View
									style={{
										width: "100%",
										padding: "10%",
										alignItems: "center",
										justifyContent: "center",
									}}>
									<Text
										style={{
											fontFamily: "BauerBodoni",
											color: "#DC8699",
											fontSize: 25,
											marginBottom: 25,
											textAlign: "justify",
										}}>
										Decoding the myths of retinol
									</Text>

									<View
										style={{
											backgroundColor: "#FFF",
											borderRadius: 15,
											padding: 15,
										}}>
										<Text
											style={{
												fontFamily: "Gotham",
												color: "#DC8699",
												fontSize: 16,
												marginVertical: 10,
												// textAlign: "justify",
												textAlign: "center",
											}}>
											Elizabeth Arden X EL&N Café London
											invite you to experience all things{" "}
											<Text
												style={{
													fontFamily: "Gotham",
													color: "#DC8699",
													fontSize: 16,
													fontWeight: "700",
												}}>
												Retinol
											</Text>
										</Text>
										<Text
											style={{
												fontFamily: "Gotham",
												color: "#DC8699",
												fontSize: 16,
												marginVertical: 10,
												// textAlign: "justify",
												textAlign: "center",
											}}>
											Join us for a morning pamper session
											where we will be introducing the new
											Retinol + HPR Ceramide Rapid Skin
											Renewing Water Cream
										</Text>
										<Text
											style={{
												fontFamily: "Gotham",
												color: "#DC8699",
												fontSize: 14,
												textAlign: "center",
												marginTop: 30,
											}}>
											Time
										</Text>
										<Text
											style={{
												fontFamily: "Gotham",
												color: "#DC8699",
												fontSize: 14,
												fontWeight: "600",
												marginBottom: 10,
												textAlign: "center",
											}}>
											10h00
										</Text>
										<Text
											style={{
												fontFamily: "Gotham",
												color: "#DC8699",
												fontSize: 14,
												textAlign: "center",
											}}>
											Event date
										</Text>
										<Text
											style={{
												fontFamily: "Gotham",
												color: "#DC8699",
												fontSize: 14,
												fontWeight: "600",
												marginBottom: 10,
												textAlign: "center",
											}}>
											{moment(
												this.state.eventDate,
												"DD/MM/YYYY",
											).format("DD MMMM YYYY")}{" "}
											(
											{moment(
												this.state.eventDate,
												"DD/MM/YYYY",
											).fromNow()}
											)
										</Text>

										<Text
											style={{
												fontFamily: "Gotham",
												color: "#DC8699",
												fontSize: 14,
												textAlign: "center",
											}}>
											Location:
										</Text>
										<Text
											style={{
												fontFamily: "Gotham",
												color: "#DC8699",
												fontSize: 14,
												marginBottom: 10,
												fontWeight: "600",
												textAlign: "center",
											}}>
											EL &N Café, Mall of Africa, Mall Of
											Africa, Midrand South Africa
										</Text>
										<Text
											style={{
												fontFamily: "Gotham",
												color: "#DC8699",
												fontSize: 14,
												textAlign: "center",
											}}>
											Dress code:
										</Text>
										<Text
											style={{
												fontFamily: "Gotham",
												color: "#DC8699",
												fontSize: 16,
												fontWeight: "600",
												marginBottom: 10,
												textAlign: "center",
											}}>
											Pink / Rose Gold
										</Text>
									</View>
								</View>

								<Fade>
									{this.state.userData === "" ? (
										<TouchableOpacity
											onPress={() => {
												this.setState({
													rsvpForm: true,
												});
											}}
											style={{
												width: "100%",
												marginLeft: "auto",
												marginRight: "auto",
												padding: "3%",
											}}>
											<View
												style={{
													width: "100%",
													marginLeft: "auto",
													marginRight: "auto",
													marginBottom: "3%",
													alignItems: "center",
													justifyContent: "center",
													overflow: "hidden",
													borderRadius: 10,
													backgroundColor: "#DC8699",
													padding: "3%",
												}}>
												<Text
													style={{
														color: "#FFF",
														fontWeight: "700",
														fontSize: 17,
													}}>
													Accept invite
												</Text>
											</View>
										</TouchableOpacity>
									) : (
										<View />
									)}
								</Fade>

								<View
									style={{
										width: "100%",
										marginLeft: "auto",
										marginRight: "auto",
										marginBottom: "3%",
										alignItems: "center",
										justifyContent: "center",
										overflow: "hidden",
										borderRadius: 10,
									}}>
									<Image
										source={require("./assets/retinol.jpg")}
										style={{
											width: width * 0.9,
											height: width * 0.9,
											resizeMode: "cover",
											overflow: "hidden",
											borderRadius: 10,
										}}
									/>
								</View>
								<View
									style={{
										width: "100%",
										marginLeft: "auto",
										marginRight: "auto",
										marginBottom: "3%",
										alignItems: "center",
										justifyContent: "center",
										overflow: "hidden",
										borderRadius: 10,
									}}>
									<Image
										source={require("./assets/ret_1.jpg")}
										style={{
											width: width * 0.9,
											height: width * 0.9,
											resizeMode: "cover",
											overflow: "hidden",
											borderRadius: 10,
										}}
									/>
								</View>
							</View>
						</View>
					</ScrollView>
				</View>

				{this.state.rsvpForm ? this.rsvpForm() : null}

				{this.loadingScreenInitial()}
			</View>
		);
	}

	rsvpForm = () => {
		return (
			<Modal
				animationType='fade'
				transparent={true}
				visible={true}
				onRequestClose={() => {
					this.setState({
						rsvpForm: !this.state.rsvpForm,
					});
				}}>
				<View
					style={{
						flex: 1,
						position: "absolute",
						zIndex: 999,
						// backgroundColor: "rgba(52, 52, 52, 1)",
						backgroundColor: "#DC8699",
						width: window.innerWidth,
						height: window.innerHeight,
					}}>
					<RsvpForm
						title={this.state.eventTitle}
						close={() => {
							this.setState({
								rsvpForm: !this.state.rsvpForm,
							});

							this.getLocalData();
						}}
					/>
				</View>
			</Modal>
		);
	};

	loadingScreenInitial = () => {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};
		return (
			<Modal
				animationType='fade'
				transparent={true}
				visible={!this.state.pageLoaded}>
				<View
					style={{
						flex: 1,
						position: "absolute",
						zIndex: 999,
						// backgroundColor: "rgba(52, 52, 52, 1)",
						backgroundColor: "#DC8699",
						width: window.innerWidth,
						height: window.innerHeight,
					}}>
					<View
						style={{
							width: "100%",
							alignItems: "center",
							justifyContent: "center",
							// backgroundColor: "#FFF9FB",
							position: "absolute",
							top: 0,
						}}>
						<img
							src={require("./assets/top.png")}
							alt='Description'
							className='responsive'
						/>
						<View
							style={{
								width: "60%",
								position: "absolute",
								zIndex: 99,
								top: "60%",
							}}>
							<img
								src={require("./assets/tiktok.svg").default}
								alt='Description'
								className='responsive'
							/>
						</View>
					</View>
					<View
						style={{
							justifyContent: "center",
							alignItems: "center",
							flex: 1,
						}}>
						<ActivityIndicator color='#FFF' size={"small"} />
						<Text
							style={{
								fontSize: 16,
								fontWeight: "400",
								color: "#FFF",
							}}>
							Loading invite...
						</Text>
						{/* <View style={{}}>
							<Lottie
								options={defaultOptions}
								height={window.innerWidth * 0.8}
								width={window.innerWidth}
							/>
						</View> */}
					</View>
				</View>
			</Modal>
		);
	};
}
