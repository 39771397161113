import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
} from "react-native";
import tick from "./assets/images/tick.png";

function capitalizeName(name) {
	let remNumber = name.replace(/[0-9]/g, "");
	return remNumber.replace(/\b(\w)/g, (s) => s.toUpperCase());
}
function isValidName(myval) {
	var validCharactersRegex = /^[a-zA-Z\-]+$/;

	return validCharactersRegex.test(myval.trim());
}

function notSpecialChars(t) {
	return t.replace(/[^\w\s]/gi, "");
}

export default class FullName extends Component {
	constructor(props) {
		super(props);
		this.state = {
			first_name: "",
			last_name: "",
			title_name: "",
		};
		this._timeout = 500;
	}
	render() {
		return (
			<View
				style={{
					width: "100%",
					// flexDirection: 'row',
				}}>
				<View
					style={{
						flex: 1,

						// marginRight: 10,
						marginTop: 10,
					}}>
					<TextInput
						style={{
							width: "100%",
							// paddingLeft: 40,
							marginBottom: 5,
							padding: 13,
							marginTop: 0,
							fontSize: 17,
							borderRadius: 15,
							textAlign: "center",
							color: "#000",
							fontWeight:
								this.state.first_name !== "" ? "500" : "200",
							fontFamily: "Avenir",
							letterSpacing: 0.26,
							borderWidth: this.state.first_name !== "" ? 0 : 0.3,
							backgroundColor: "#FFF",
							borderColor: "#000",
						}}
						autoFocus
						placeholderTextColor={"#797979"}
						autoCapitalize='none'
						placeholder={"First name"}
						value={this.state.first_name}
						onChangeText={(text) => {
							const textA = capitalizeName(text);
							const validName_ = isValidName(textA);
							let length_ = textA.length > 1 ? true : false;

							this.setState({
								first_name: textA,
								first_nameValid: validName_ && length_,
							});

							// clearTimeout(this._timeout);
							// this._timeout = setTimeout(() => {
							this.props.first_name(textA);
							// }, 500);
						}}
					/>
					<Image
						source={tick}
						style={{
							width: 16,
							height: 16,
							resizeMode: "contain",
							position: "absolute",
							left: 15,
							top: 18,
							opacity: this.state.first_nameValid ? 1 : 0.2,
						}}
					/>
				</View>
				<View
					style={{
						flex: 1,

						marginTop: 10,
					}}>
					<TextInput
						style={{
							width: "100%",
							// paddingLeft: 40,
							// marginBottom: 5,
							padding: 13,
							marginTop: 0,
							fontSize: 17,
							borderRadius: 15,
							textAlign: "center",
							color: "#000",
							fontWeight:
								this.state.last_name !== "" ? "500" : "200",
							fontFamily: "Avenir",
							letterSpacing: 0.26,
							borderWidth: this.state.last_name !== "" ? 0 : 0.3,
							backgroundColor: "#FFF",
							borderColor: "#000",
						}}
						placeholderTextColor={"#797979"}
						autoCapitalize='none'
						placeholder={"Last name"}
						value={this.state.last_name}
						onChangeText={(text) => {
							const textA = capitalizeName(text);
							const validName_ = isValidName(textA);
							let length_ = textA.length > 1 ? true : false;

							this.setState({
								last_name: textA,
								last_nameValid: validName_ && length_,
							});

							// clearTimeout(this._timeout);
							// this._timeout = setTimeout(() => {
							this.props.last_name(textA);
							// }, 500);
						}}
					/>
					<Image
						source={tick}
						style={{
							width: 16,
							height: 16,
							resizeMode: "contain",
							position: "absolute",
							left: 15,
							top: 18,
							opacity: this.state.last_nameValid ? 1 : 0.2,
						}}
					/>
				</View>

				{/* <View
          style={{
            flex: 1,

            marginTop: 10,
          }}>
          <TextInput
            style={{
              width: '100%',
              // paddingLeft: 40,
              // marginBottom: 5,
              padding: 13,
              marginTop: 0,
              fontSize: 17,
              borderRadius: 15,
              textAlign: 'center',
              color: '#000',
              fontWeight: this.state.title_name !== '' ? '500' : '200',
              fontFamily: 'Avenir',
              letterSpacing: 0.26,
              borderWidth: this.state.title_name !== '' ? 0 : 0.3,
              backgroundColor: '#FFF',
              borderColor: '#000',
            }}
            placeholderTextColor={'#797979'}
            autoCapitalize="none"
            placeholder={'Title'}
            value={this.state.title_name}
            onChangeText={text => {
              const textA = capitalizeName(text);
              const validName_ = isValidName(textA);
              let length_ = textA.length > 1 ? true : false;

              this.setState({
                title_name: textA,
                title_nameValid: validName_ && length_,
              });

              // clearTimeout(this._timeout);
              // this._timeout = setTimeout(() => {
              this.props.name_title(textA);
              // }, 500);
            }}
          />
          <Image
            source={tick}
            style={{
              width: 16,
              height: 16,
              resizeMode: 'contain',
              position: 'absolute',
              left: 15,
              top: 18,
              opacity: this.state.title_nameValid ? 1 : 0.2,
            }}
          />
        </View> */}
			</View>
		);
	}
}
